import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemTextProps,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import {
  CalculationRelationship,
  IApiFact,
  LabeledElement,
  LabelsForElementByName,
} from "../../api/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  enumerationItemTypeName,
  enumerationSetItemTypeName,
  factValueTextLengthCutoff,
  getAccuracyDisplayValue,
} from "../../helpers/constants";
import {
  getFactDisplayLabelFromLebeledElements,
  getFactDisplayLabelFromLinkbaseTree,
  getFactDisplayLabelFromRoleType,
  getFactDisplayLabelWithoutLabeledElements,
} from "../../helpers/getFactDisplayLabel";
import { getViewerSidebarDateString } from "../../helpers/getViewerSidebarDateString";
import { getValueString } from "../../helpers/getValueString";
import { ReactComponent as AbstractionIcon } from "../../images/icons/abstractitem_black.svg";
import { ReactComponent as DimensionIcon } from "../../images/icons/dimension_black.svg";
import GeneralSidebarDuplicateFactSwitcherProps from "./generalSidebarDuplicateFactSwitcher";
import FactHtmlValueDisplay from "../facts/factHtmlValueDisplay";
import LargeTextValueDisplay from "../facts/largeTextValueDisplay";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import {
  factSelectionCallerUpdated,
  selectedFactIdsUpdated,
} from "../../reducers/internalLifeCycleReducer";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  startGetElementLabelsByNamespaceAndElementName,
  startUpdateLinkbaseTree,
} from "../../reducers/extractionReducer";
import { useEffect, useMemo, useState } from "react";
import { groupArrayByProperty } from "../../helpers/groupArrayByProperty";
import CalculationRelationashipDisplayGroup from "./calculationRelationashipDisplayGroup";
import { getAssociatedFactIds } from "../../helpers/getAssociatedFactIds";
import { getEnumerationValueSets } from "../../helpers/getEnumerationValueSets";

export interface FactViewerAccordionProps {
  displayFact: IApiFact;
  allFacts: IApiFact[];
  activeEntryPoint: string;
  currentSelectedLanguage: string;
  labeledElements: LabeledElement[] | undefined;
  selectedFactIds: string[];
}

interface CustomListItemProps {
  header: string;
  text?: string | JSX.Element;
  mapText?: React.ReactNode[];
  title?: string;
  shouldShowFullText?: boolean;
  opacity?: number;
  customSecondaryTypographyProps?: ListItemTextProps["secondaryTypographyProps"];
  customSecondaryTypoBackground?: string;
}

export const CustomListItem = ({
  header,
  text,
  mapText,
  title,
  shouldShowFullText,
  opacity,
  customSecondaryTypographyProps,
  customSecondaryTypoBackground,
}: CustomListItemProps) => {
  return (
    <ListItem disableGutters>
      <ListItemText
        primaryTypographyProps={{
          noWrap: true,
          whiteSpace: "initial",
          color: "#0F5385",
          fontWeight: 600,
        }}
        primary={header}
        secondaryTypographyProps={{
          ...customSecondaryTypographyProps,
          backgroundColor: customSecondaryTypoBackground
            ? customSecondaryTypoBackground
            : undefined,
          noWrap: shouldShowFullText ? false : true,
          whiteSpace: "initial",
        }}
        sx={{
          wordBreak: shouldShowFullText ? "break-all" : "normal",
          opacity: opacity,
        }}
        secondary={mapText || text}
        title={title}
      />
    </ListItem>
  );
};

const FactViewerAccordion = ({
  displayFact,
  allFacts,
  activeEntryPoint,
  currentSelectedLanguage,
  labeledElements,
  selectedFactIds,
}: FactViewerAccordionProps) => {
  const dispatch = useAppDispatch();

  const presentationTree = useAppSelector((state) => {
    const mapping = state.extract.linkbaseTreeMappings.find(
      (map) => map.entryPoint?.href === activeEntryPoint
    );
    const relevantTrees = mapping?.fromPackage
      ? state.extract.extractedBasicData?.linkbaseTrees
      : mapping?.trees;
    return relevantTrees?.find((tree) => tree.type.toLowerCase());
  });

  const calculationTree = useAppSelector((state) => {
    const mapping = state.extract.linkbaseTreeMappings.find(
      (map) => map.entryPoint?.href === activeEntryPoint
    );
    const relevantTrees = mapping?.fromPackage
      ? state.extract.extractedBasicData?.linkbaseTrees
      : mapping?.trees;
    return relevantTrees?.find(
      (tree) => tree.type.toLowerCase() === "calculation"
    );
  });

  const updatingValue = useAppSelector(
    (state) => state.extract.updatingElementLabels
  );
  const enumerationValueMapping = useAppSelector(
    (state) => state.extract.enumerationFactValueLabelMapping
  );

  const [label, setLabel] = useState<string | undefined>();
  const [value, setValue] = useState<string | JSX.Element>();
  const [updatingLabel, setUpdatingLabel] = useState(false);
  const associatedFactIds = useMemo(
    () => getAssociatedFactIds(displayFact.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayFact.id]
  );

  useEffect(() => {
    const getLabel = async (): Promise<void> => {
      setUpdatingLabel(true);
      let output = getFactDisplayLabelFromLinkbaseTree(
        displayFact.factElement,
        presentationTree,
        currentSelectedLanguage
      )?.label;
      if (!output) {
        output = getFactDisplayLabelFromLebeledElements(
          displayFact.factElement,
          labeledElements,
          currentSelectedLanguage
        )?.label;
      }
      if (!output) {
        const response = await dispatch(
          startUpdateLinkbaseTree({
            treeId: presentationTree?.id || "",
            elementId: displayFact.factElement.elementId,
            elementNamespaceUri: displayFact.factElement.namespace.uri,
          })
        );
        output = getFactDisplayLabelFromRoleType(
          displayFact.factElement,
          response.payload as any,
          currentSelectedLanguage
        )?.label;
      }
      if (!output && !labeledElements) {
        output = getFactDisplayLabelWithoutLabeledElements(displayFact)?.label;
      }
      setLabel(output);
      setUpdatingLabel(false);
    };
    getLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSelectedLanguage,
    presentationTree?.id,
    displayFact.factElement.elementId,
  ]);

  useEffect(() => {
    const updateValue = async (): Promise<void> => {
      let value: string | JSX.Element = "";
      if (
        displayFact.factElement?.type === enumerationItemTypeName ||
        displayFact.factElement?.type === enumerationSetItemTypeName
      ) {
        const existingLabels = enumerationValueMapping.find(
          (m) => m.factId === displayFact.id
        )?.labels;
        if (existingLabels && existingLabels.length > 0) {
          const langLabels = existingLabels
            .flatMap((el) => el.labels)
            .filter((label) => label.lang === currentSelectedLanguage);
          if (langLabels.length > 0) {
            setValue(langLabels.map((l) => l.value).join(";"));
          } else {
            setValue(
              existingLabels
                .filter((el) => el.labels.length > 0)
                .map((el) => el.labels[0].value)
                .join(";")
            );
          }
        } else {
          const valuesToRetrieve = getEnumerationValueSets(
            displayFact.contextRef?.value
          );
          if (Object.keys(valuesToRetrieve).length > 0) {
            const resp = await dispatch(
              startGetElementLabelsByNamespaceAndElementName({
                factId: displayFact.id,
                enumerationValuesToRetrieve: valuesToRetrieve,
              })
            );
            const newLabels = resp.payload as LabelsForElementByName[];
            if (newLabels && newLabels.length > 0) {
              const langLabels = newLabels
                .flatMap((el) => el.labels)
                .filter((label) => label.lang === currentSelectedLanguage);
              if (langLabels.length > 0) {
                setValue(langLabels.map((l) => l.value).join(";"));
              } else {
                setValue(
                  newLabels
                    .filter((el) => el.labels.length > 0)
                    .map((el) => el.labels[0].value)
                    .join(";")
                );
              }
            } else {
              setValue(t`Could not find label for enumeration value`);
            }
          } else {
            setValue(t`Could not find label for enumeration value`);
          }
        }
      } else {
        const isValueHtml =
          displayFact.contextRef?.escape &&
          displayFact.factElement?.type?.includes("textBlockItemType");
        const isValueExtraLong =
          !isValueHtml &&
          displayFact.formattedValue?.length > factValueTextLengthCutoff;
        value = getValueString(
          displayFact.formattedValue,
          displayFact.contextRef.unitRef || displayFact.contextRef.unit?.unitId,
          displayFact.contextRef.decimals
        );
        if (isValueHtml) {
          value = (
            <FactHtmlValueDisplay item={displayFact} label={label || ""} />
          );
        } else if (isValueExtraLong) {
          value = <LargeTextValueDisplay text={displayFact.formattedValue} />;
        }
        setValue(value);
      }
    };
    updateValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentationTree?.id, displayFact.id, currentSelectedLanguage, label]);

  const groupedCalcs = useMemo(() => {
    if (
      !displayFact.calculationRelationships ||
      displayFact.calculationRelationships.length === 0
    )
      return [];
    return Array.from(
      groupArrayByProperty(displayFact.calculationRelationships, "roleUri")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayFact.id]);
  return (
    <Accordion defaultExpanded sx={{ pl: 1, pr: 2 }}>
      <AccordionSummary
        id="viewer_sidebar_fact_details_header"
        expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <Trans>Fact details</Trans>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense>
          <CustomListItem
            header={t`Concept`}
            text={displayFact.factElement.elementId.replace("_", ":")}
            title={displayFact.factElement.elementId.replace("_", ":")}
            shouldShowFullText={true}
          />
          <CustomListItem
            header={t`Label`}
            text={label || ""}
            title={label || ""}
            opacity={updatingLabel ? 0.3 : 1}
          />
          <CustomListItem
            header={t`Date`}
            text={getViewerSidebarDateString(
              displayFact.contextRef.context.period
            )}
          />
          <CustomListItem
            header={t`Value`}
            text={value}
            customSecondaryTypographyProps={{
              color: (theme) =>
                displayFact.formattedValue &&
                !isNaN(parseInt(displayFact.formattedValue)) &&
                parseInt(displayFact.formattedValue) < 0
                  ? theme.palette.error.main
                  : "rgba(0, 0, 0, 0.6)",
            }}
            opacity={updatingValue ? 0.3 : 1}
          />
          {displayFact.contextRef.value &&
            !isNaN(parseInt(displayFact.contextRef.value)) && (
              <CustomListItem
                header={t`Accuracy`}
                text={getAccuracyDisplayValue(displayFact.contextRef.decimals)}
              />
            )}
          {displayFact.contextRef.context.scenario?.dimensions &&
            displayFact.contextRef.context.scenario?.dimensions.length > 0 && (
              <CustomListItem
                header={t`Dimensions`}
                mapText={displayFact.contextRef.context.scenario.dimensions.map(
                  (dimension) => (
                    <>
                      <ListItemIcon sx={{ minWidth: 25 }}>
                        <DimensionIcon />
                      </ListItemIcon>
                      <ListItemText
                        secondary={dimension.dimension.name}
                        title={dimension.dimension.name}
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                      <ListItemIcon
                        sx={{
                          minWidth: 25,
                          display: "flex",
                          alignItems: "flex-start",
                          alignSelf: "flex-start",
                        }}>
                        <AbstractionIcon /> {dimension?.typedDimensionValue}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          overflowWrap: "anywhere",
                        }}
                        secondary={dimension.dimensionMember.name}
                        title={dimension.dimensionMember.name}
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </>
                  )
                )}
              />
            )}
          {displayFact.footnotes && displayFact.footnotes?.length > 0 && (
            <CustomListItem
              header={t`Footnotes`}
              mapText={displayFact.footnotes.map((footnote) => (
                <ListItemText
                  secondary={footnote.value}
                  title={footnote.value}
                  secondaryTypographyProps={{
                    noWrap: true,
                    whiteSpace: "initial",
                  }}
                />
              ))}
              shouldShowFullText={true}
            />
          )}
          {selectedFactIds.length === 1 && groupedCalcs.length > 0 && (
            <CustomListItem
              header={t`Calculations`}
              shouldShowFullText
              mapText={groupedCalcs.map(
                (grouping: [string, CalculationRelationship[]]) => (
                  <CalculationRelationashipDisplayGroup
                    currentSelectedLanguage={currentSelectedLanguage}
                    calculationTree={calculationTree}
                    facts={grouping[1].map((c) => c.fact)}
                    roleUri={grouping[0]}
                  />
                )
              )}
            />
          )}
          {displayFact.factElement.anchoredTo && (
            <CustomListItem
              header={t`Wider anchor`}
              text={displayFact.factElement.anchoredTo}
              title={displayFact.factElement.anchoredTo}
              shouldShowFullText
              customSecondaryTypoBackground="#f5f5f5"
              customSecondaryTypographyProps={{
                p: 1,
                m: 0.5,
              }}
            />
          )}
          {displayFact.factElement.anchoredFrom &&
            displayFact.factElement.anchoredFrom.length > 0 && (
              <CustomListItem
                header={t`Narrower anchors`}
                mapText={displayFact.factElement.anchoredFrom.map((anchor) => (
                  <ListItemText
                    sx={{
                      backgroundColor: "#f5f5f5",
                      p: 1,
                      m: 0.5,
                      wordBreak: "break-all",
                    }}
                    secondary={anchor}
                    title={anchor}
                    secondaryTypographyProps={{
                      noWrap: false,
                    }}
                  />
                ))}
              />
            )}
          {associatedFactIds.length > 0 && (
            <CustomListItem
              header={t`Additional applied facts on section`}
              mapText={[
                <List
                  sx={{
                    ".MuiTabs-indicator": {
                      left: 0,
                    },
                  }}>
                  {associatedFactIds.map((id) => {
                    const fact = allFacts.find((f) => f.id === id);
                    const label = getFactDisplayLabelFromLinkbaseTree(
                      fact?.factElement,
                      presentationTree,
                      currentSelectedLanguage
                    );
                    return (
                      <ListItemButton
                        key={`sidebar_fact_viewer_associated_facts_tab_${id}`}
                        onClick={() => {
                          dispatch(selectedFactIdsUpdated(id));
                          dispatch(factSelectionCallerUpdated("sidebar"));
                        }}
                        sx={[
                          {
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.grey[500],
                            },
                          },
                        ]}>
                        <ListItemText
                          primary={label?.label || ""}
                          primaryTypographyProps={{
                            variant: "body2",
                          }}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>,
              ]}
            />
          )}
        </List>
        <GeneralSidebarDuplicateFactSwitcherProps
          allFacts={allFacts}
          currentFact={displayFact}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default FactViewerAccordion;
